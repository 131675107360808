import Header from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";

import Home from "./pages/Home";
import BatteryPacks from "./pages/BatteryPacks";
import Storage from "./pages/Storage";
import BMS from "./pages/BMS";
import Renon360 from "./pages/Renon360";
import Product from "./pages/Product";
import Design from "./pages/Design";
import QA from "./pages/QA";
import Certifications from "./pages/Certs";
import Interop from "./pages/Interop";
import Lab from "./pages/Lab";
import RenonApp from "./pages/RenonApp";
import Datasheets from "./pages/Datasheets";
import PackSizeCalculator from "./pages/PackSizeCalculator";
import Applications from "./pages/Applications";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Mobility from "./pages/Mobility";
import ScrollToTop from "./components/ScrollToTop";
import ScrollButton from "./components/ScrollComponent";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Solar from "./pages/Solar";
import IndustrialEVs from "./pages/IndustrialEVs";
import CommercialEVs from "./pages/CommercialEVs";
import Team from "./pages/Team";
import DetailedBlog from "./pages/DetailedBlog";

function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/home" exact element={<Home />} />
          <Route path="/platform/mobility" element={<Mobility />} />
          <Route path="/platform/e2wPacks" element={<BatteryPacks />} />
          <Route path="/platform/devices" element={<Storage />} />
          <Route path="/platform/cloud" element={<BMS />} />
          <Route path="/software/ai" element={<Renon360 />} />
          <Route path="/software/product" element={<Product />} />
          <Route path="/software/design" element={<Design />} />
          <Route path="/software/qa" element={<QA />} />
          <Route path="/software/interop" element={<Interop />} />
          <Route path="/software/certifications" element={<Certifications />} />
          <Route path="/software/lab" element={<Lab />} />
          <Route path="/resources/datasheets" element={<Datasheets />} />
          <Route path="/resources/packCalc" element={<PackSizeCalculator />} />
          <Route path="/resources/applications" element={<Applications />} />
          <Route path="/resources/blog" element={<Blog />} />
          {/* <Route
            path="/resources/detailedBlog/:id"
            element={<DetailedBlog />}
          /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/solar" element={<Solar />} />
          <Route path="/commercialEVs" element={<CommercialEVs />} />
          <Route path="/industrialEVs" element={<IndustrialEVs />} />
        </Routes>
        <ScrollButton />
      </ScrollToTop>
      <Footer />
    </Router>
  );
}

export default App;
